import { MarketingStat } from 'components/MarketingReport/types';
import { ROUTES } from 'constants/routes';
import { AuthContextProps } from 'react-oidc-context';
import { NavAddress, PropertyList, SlimProperty } from 'types/app';
import {
  MarketingPortals,
  PortfolioProperties,
  PortfolioProperty,
  Property,
  SidebarPortfolioProperty,
} from 'types/property';

export const getSuffix = (
  arr: SlimProperty[] | PortfolioProperty[] | SidebarPortfolioProperty[],
) => {
  return arr.length ? `(${arr.length})` : '';
};

export const formatStreetAddress = ({
  buildingName,
  buildingNumber,
  line1,
  postcode,
}: NavAddress) => {
  let streetAddress = '';
  if (buildingName) {
    streetAddress += `${buildingName}, `;
  }
  if (buildingNumber) {
    streetAddress += `${buildingNumber}`;
  }
  if (line1) {
    if (buildingNumber) {
      streetAddress += ' ';
    }
    streetAddress += `${line1}`;
  }
  if (postcode) {
    streetAddress += `, ${postcode}`;
  }
  return streetAddress;
};

export function debounce<T>(fn: (...args: T[]) => void, ms = 250) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: T[]) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
      timer = null;
    }, ms);
  };
}

export function getVutureApiUrl() {
  return window.appConfig.vutureHeaderUri;
}

export const getMarketingPortals = (
  marketingStats: MarketingStat[],
  property: Property,
) => {
  const portals = [];
  if (
    marketingStats.find(
      (stat) => stat.rightMovePageViews || stat.rightMoveSearchImpressions,
    )
  ) {
    portals.push(MarketingPortals.RIGHTMOVE);
  }

  if (
    marketingStats.find(
      (stat) => stat.zooplaPageViews || stat.zooplaSearchImpressions,
    )
  ) {
    portals.push(MarketingPortals.ZOOPLA);
  }

  if (
    marketingStats.find(
      (stat) => stat.savillsPageViews || stat.savillsSearchImpressions,
    ) ||
    property.savillsWebsiteUrl
  ) {
    portals.push(MarketingPortals.SAVILLS);
  }

  return portals;
};

export const checkIfEnabled = (auth?: AuthContextProps) => {
  return (
    auth?.user?.profile?.my_savills_user === 'true' ||
    auth?.user?.profile?.my_savills_vendor_enabled === 'true' ||
    auth?.user?.profile?.my_savills_landlord_enabled === 'true'
  );
};

export const formatFrequency = (frequency?: string) => {
  switch (frequency) {
    case 'weekly':
      return 'PW';
    case 'monthly':
      return 'PM';
    case 'annually':
      return 'PA';
    default:
      return frequency;
  }
};

export const getHomeView = (
  propertyList: PropertyList,
  pathName: string,
  isUserConnected?: boolean,
  showApplicant?: boolean,
  portfolioProperties?: PortfolioProperties,
) => {
  const selling = propertyList?.selling || [];
  const letting = propertyList?.letting || [];
  const isHomeRoute = pathName === ROUTES.HOME;

  switch (true) {
    case selling.length > 0 && isHomeRoute && isUserConnected:
      return `${ROUTES.VENDORS.ROOT}/${selling[0].id}`;

    case letting.length > 0 &&
      isHomeRoute &&
      isUserConnected &&
      (portfolioProperties?.personalProperties?.length ?? 0) > 0: {
      return `${ROUTES.LETTING.PERSONAL_PORTFOLIO}`;
    }

    case letting.length > 0 &&
      isHomeRoute &&
      isUserConnected &&
      (portfolioProperties?.companyProperties?.length ?? 0) > 0: {
      return `${portfolioProperties?.companyProperties[0].link}`;
    }

    case isHomeRoute && showApplicant:
      return ROUTES.APPLICANT.ROOT;

    case selling.length === 0 && !showApplicant && isHomeRoute:
      return `${ROUTES.NO_PROPERTIES}`;

    default:
      return undefined;
  }
};
