import { LandlordStepCodes } from 'components/PropertyHeader/types';
import { LettingStatus, PropertyPageComponents } from 'types/property';

export type LandlordViewConfigProps = {
  [key in LandlordStepCodes]: PropertyPageComponents[];
};

export type LettingStatusStepCodeMap = {
  [key in LettingStatus]: LandlordStepCodes;
};

export const MANAGED_LETTING_CODE = 'managed';

export const LETTING_STATUS: LettingStatusStepCodeMap = {
  [LettingStatus.MarketAppraisal]: LandlordStepCodes.Appraisal,
  [LettingStatus.OnTheMarketFirstTime]: LandlordStepCodes.OnTheMarket,
  [LettingStatus.OnTheMarketReLet]: LandlordStepCodes.OnTheMarket,
  [LettingStatus.ArrangingTenancy]: LandlordStepCodes.ArrangingTenancy,
  [LettingStatus.ActiveTenancy]: LandlordStepCodes.ActiveTenancy,
  [LettingStatus.Complete]: LandlordStepCodes.Completed,
  [LettingStatus.Withdrawn]: LandlordStepCodes.Appraisal,
  [LettingStatus.BookValuation]: LandlordStepCodes.Appraisal,
  [LettingStatus.Empty]: LandlordStepCodes.Appraisal,
  [LettingStatus.Removed]: LandlordStepCodes.Appraisal,
  [LettingStatus.TenancyAgreed]: LandlordStepCodes.ActiveTenancy,
  [LettingStatus.ChangeOfSharer]: LandlordStepCodes.ActiveTenancy,
  [LettingStatus.Invalid]: LandlordStepCodes.Invalid,
  [LettingStatus.ActiveTenancyWithRenewal]:
    LandlordStepCodes.ActiveTenancyWithRenewal,
  [LettingStatus.EndOfTenancyConfirmed]:
    LandlordStepCodes.EndOfTenancyConfirmed,
  [LettingStatus.ActiveTenancyChangeOfSharer]:
    LandlordStepCodes.ActiveTenancyWithRenewal,
  [LettingStatus.DepositRelease]: LandlordStepCodes.EndOfTenancyConfirmed,
  [LettingStatus.DeterminedByTenancies]: LandlordStepCodes.Appraisal,
  [LettingStatus.Hidden]: LandlordStepCodes.Invalid,
  [LettingStatus.ActiveEndNear]: LandlordStepCodes.ActiveTenancyWithRenewal,
};

export const LANDLORD_VIEW_CONFIG: LandlordViewConfigProps = {
  [LandlordStepCodes.Appraisal]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.DEAL_PROGRESS,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.RESOURCES,
    PropertyPageComponents.RECENTLY_LET_PROPERTIES,
    PropertyPageComponents.TEAM_CONTACT,
  ],
  [LandlordStepCodes.ArrangingTenancy]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.DEAL_PROGRESS,
    PropertyPageComponents.ARRANGING_TENANCY_PROGRESS,
    PropertyPageComponents.RENEWAL_PROGRESS,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.TEAM_CONTACT,
  ],
  [LandlordStepCodes.OnTheMarket]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.DEAL_PROGRESS,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.OFFERS,
    PropertyPageComponents.FEEDBACK,
    PropertyPageComponents.MARKETING_STATS,
    PropertyPageComponents.TEAM_CONTACT,
  ],
  [LandlordStepCodes.ActiveTenancy]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.IN_TENANCY_TIMELINE,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.TEAM_CONTACT,
    PropertyPageComponents.TENANT_CONTACT,
  ],
  [LandlordStepCodes.ActiveTenancyWithRenewal]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.IN_TENANCY_TIMELINE,
    PropertyPageComponents.RENEWAL_PROGRESS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TEAM_CONTACT,
    PropertyPageComponents.TENANT_CONTACT,
  ],
  [LandlordStepCodes.EndOfTenancyConfirmed]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.IN_TENANCY_TIMELINE,
    PropertyPageComponents.TENANCY_ENDING_PROGRESS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TEAM_CONTACT,
    PropertyPageComponents.TENANT_CONTACT,
  ],
  [LandlordStepCodes.Completed]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.DEAL_PROGRESS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.TENANCY_ENDING_PROGRESS,
    PropertyPageComponents.ARCHIVED_DOCUMENTS,
  ],
  [LandlordStepCodes.Invalid]: [
    PropertyPageComponents.PROPERTY_INFO,
    PropertyPageComponents.DOCUMENTS,
    PropertyPageComponents.TASKS,
    PropertyPageComponents.EVENTS,
    PropertyPageComponents.TEAM_CONTACT,
    PropertyPageComponents.TENANT_CONTACT,
  ],
};

const createSignpost = (key: string, link: string) => ({
  key,
  link,
});

export const LANDLORD_SIGNPOSTS = [
  createSignpost(
    'sellingResidential',
    'https://www.savills.co.uk/services/buying-or-selling/selling-a-residential-property.aspx',
  ),
  createSignpost(
    'sellingCommercial',
    'https://www.savills.co.uk/services/buying-or-selling/disposing-of-commercial-property.aspx',
  ),
  createSignpost(
    'sellingFarm',
    'https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-a-farm.aspx',
  ),
  createSignpost(
    'lettingResidential',
    'https://www.savills.co.uk/services/letting-or-renting/letting-a-residential-property.aspx',
  ),
  createSignpost(
    'lettingCommercial',
    'https://www.savills.co.uk/services/letting-or-renting/leasing-a-commercial-property.aspx',
  ),
  createSignpost(
    'auction',
    'https://www.savills.co.uk/services/buying-or-selling/auctioning-a-property.aspx',
  ),
  createSignpost(
    'newBuild',
    'https://www.savills.co.uk/services/buying-or-selling/selling-new-build-property.aspx',
  ),
  createSignpost(
    'internationalSelling',
    'https://www.savills.co.uk/services/buying-or-selling/buying-and-selling-international-property.aspx',
  ),
  createSignpost(
    'internationalLetting',
    'https://www.savills.co.uk/services/letting-or-renting/letting-or-renting-international-property.aspx',
  ),
];

export const BOOK_VALUATION_URL =
  'https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056';

export const LIVE_TENANCY_STATUSES = [
  LettingStatus.ActiveTenancyWithRenewal,
  LettingStatus.EndOfTenancyConfirmed,
  LettingStatus.DepositRelease,
  LettingStatus.ActiveTenancyChangeOfSharer,
  LettingStatus.ActiveTenancy,
  LettingStatus.ActiveEndNear,
  LandlordStepCodes.ActiveTenancy,
];

export enum LANDLORD_TASK_MODAL_IDS {
  UPDATE_CERT = 'uploadCert',
  UPDATE_LICENSE = 'updateLicense',
  ID_VERIFICATION = 'idVerification',
}

export const ID_VERIFICATION_ML_PDF =
  'https://pdf.savills.com/AML%20Flyer%20-%202023.pdf';

export const TF_ANDROID =
  'https://play.google.com/store/apps/details?id=app.thirdfort.android&hl=en_GB&pli=1';
export const TF_IOS = 'https://apps.apple.com/gb/app/thirdfort/id1517893677';
