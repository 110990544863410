import { createGlobalStyle } from 'styled-components';
import 'savills-ui-kit/lib/index.css';
import theme from 'styled/theme';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    outline: none;
  }

  html {
    font-size: 100%;
    line-height: 1.4;
    -webkit-text-size-adjust: 100%;
  }

  .hide-hj {
    ._hj_feedback_container,
    ._hj-widget-container {
      display: none!important;
    }
  }

  body {
    height: 100%;
    margin: 0;
    font-size: 16px;
    background: ${theme.hub.colorPalette.misc.lightGrey};
    color: ${theme.hub.colorPalette.font.ink};
    font-family: Arial, sans-serif;
    min-height: 100vh;

    &.no-scroll {
      overflow-y: hidden;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  #root {
    z-index: 1;
    position: relative;
    min-height: 100vh;
    margin: 0 auto;
    background-color: ${theme.hub.colorPalette.misc.lightGrey};
  }

  #modal-root {
    z-index: 2;
    position: relative;
  }

  .map-overlay-view-container {
    cursor: auto;
    height: 0;
    position: absolute;
  }

  .map-overlay-view-anchor {
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid ${theme.hub.colorPalette.primary.darkBlue};
    }
  }

  .map-overlay-view-content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background-color: ${theme.hub.colorPalette.primary.darkBlue};
    border-radius: 5px;
    font-family: sans-serif;
    overflow-y: auto;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
  }

  .tox .tox-statusbar {
    display: none !important;
  }

  .tox-notification.tox-notification--in.tox-notification--warning {
    display: none !important;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    font-family: ${(props) => props.theme.fonts.primary.font};
    color: ${theme.hub.colorPalette.font.ink};
  }

`;

export default GlobalStyle;
